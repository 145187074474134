import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PaymentInfo.css';
import { db, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp, updateDoc, getDocs, query, where, doc, getDoc, setDoc } from 'firebase/firestore';
import { useCart } from '../contexts/CartContext';

const PaymentInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, shippingCost, cart } = location.state;
  const [proofURL, setProofURL] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [mbWayNumber, setMbWayNumber] = useState('');
  const { clearCart } = useCart();

  // Generate a 6-digit order number
  const generateOrderNumber = () => {
    return Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit number
  };

  // Generate the order number once and store it in state
  const [orderNumber, setOrderNumber] = useState(generateOrderNumber());

  // Ensure the order number is generated only once when the component mounts
  useEffect(() => {
    if (!orderNumber) {
      setOrderNumber(generateOrderNumber());
    }
  }, [orderNumber]);

  // MBWay logic: track counts and update number to display
  const getCurrentMonth = () => new Date().getMonth() + 1; // Get current month (1-12)
  
  const handleMBWayLogic = async () => {
    const month = getCurrentMonth();
    const mbWayRef = doc(db, 'mbwayCounts', month.toString());
    const mbWayDoc = await getDoc(mbWayRef);

    if (!mbWayDoc.exists()) {
      // If no data for this month, initialize counts
      await setDoc(mbWayRef, { count1: 0, count2: 0 });
      setMbWayNumber('914 126 043'); // Start with number 1
    } else {
      const { count1, count2 } = mbWayDoc.data();
      if (count1 < 35) {
        setMbWayNumber('914 126 043'); // Show number 1
      } else if (count2 < 40) {
        setMbWayNumber('910 899 094'); // Show number 2
      } else {
        setMbWayNumber(null); // MBWay is unavailable
      }
    }
  };

  useEffect(() => {
    if (formData.paymentMethod === 'MBWay') {
      handleMBWayLogic(); // Only run MBWay logic if payment method is MBWay
    }
  }, [formData.paymentMethod]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `proofs/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      setUploading(true);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Upload error: ', error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at: ', downloadURL);
          setProofURL(downloadURL);
          setUploading(false);
        }
      );
    }
  };

  const updateProductStock = async () => {
    for (const item of cart) {
      const productQuery = query(collection(db, 'products'), where('code', '==', item.code));
      const productSnapshot = await getDocs(productQuery);
      if (!productSnapshot.empty) {
        const productDoc = productSnapshot.docs[0]; // Assuming codes are unique and there's only one document
        const productRef = productDoc.ref;
        const currentStock = productDoc.data().stock;
        const newStock = currentStock - item.quantity;
        await updateDoc(productRef, { stock: newStock });
      } else {
        console.error(`Product with code ${item.code} does not exist.`);
      }
    }
  };

  const handleSubmit = async () => {
    if (!proofURL) {
      alert('Por favor, faça upload do comprovativo de pagamento.');
      return;
    }
  
    const orderData = {
      ...formData,
      shippingCost,
      total: cart.reduce((sum, item) => sum + item.price * item.quantity, 0) + shippingCost,
      cart,
      proofURL,
      status: 'Confirmar Pagamento',
      timestamp: serverTimestamp(),
      orderNumber, // Use the stored order number
    };
  
    try {
      // Update product stock before creating the order
      await updateProductStock();
  
      // Check if payment method is MBWay
      if (formData.paymentMethod === 'MBWay') {
        const month = getCurrentMonth();
        const mbWayRef = doc(db, 'mbwayCounts', month.toString());
        const mbWayDoc = await getDoc(mbWayRef);
  
        if (mbWayDoc.exists()) {
          const { count1, count2 } = mbWayDoc.data();
  
          // Update the count for MBWay number 1 or 2
          if (count1 < 35) {
            await updateDoc(mbWayRef, { count1: count1 + 1 });
          } else if (count2 < 40) {
            await updateDoc(mbWayRef, { count2: count2 + 1 });
          } else {
            alert('MBWay não está disponível este mês.');
            return; // Prevent order submission if MBWay is unavailable
          }
        } else {
          // Initialize the count for the month if it doesn't exist
          await setDoc(mbWayRef, { count1: 1, count2: 0 });
        }
      }
  
      // Create the order in Firebase
      await addDoc(collection(db, 'orders'), orderData);
      clearCart(); // Clear the cart after successful submission
      navigate('/thank-you', { state: { orderNumber, formData, cart, shippingCost } });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  

  const renderPaymentInstructions = () => {
    switch (formData.paymentMethod) {
      case 'MBWay':
        return mbWayNumber ? (
          <div>
            <h2>Os nossos Dados</h2>
            <p>Envie por MBWay o montante para o número: <strong>{mbWayNumber}</strong></p>
          </div>
        ) : (
          <div>
            <p>MBWay não está disponível este mês. Escolha outro método de pagamento.</p>
          </div>
        );
      case 'Transferência Bancária':
        return (
          <div>
            <h2>Os nossos Dados</h2>
            <p>IBAN: <strong>PT50 0035 0686 0000 7553 8305 9</strong></p>
          </div>
        );
      case 'PayPal':
        return (
          <div>
            <h2>Os nossos Dados</h2>
            <p>Envie o montante para o PayPal: <strong>@ContaeMeia</strong></p>
          </div>
        );
      case 'Revolut':
        return (
          <div>
            <h2>Os nossos Dados</h2>
            <p>Envie por Revolut o montante para o revtag: <strong>@contaemeia2020</strong></p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="payment-info">
      <h1>Finalizar pagamento</h1>
      <div className="content">
        <div className="payment-details">
          <p className="instructions">Realiza o pagamento e submete o comprovativo abaixo.</p>
          <p className="instructions">A tua encomenda será enviada após a verificação do pagamento realizado.</p>
          <p className="instructions">Obrigada!</p>

          <hr className="divider" />
          {renderPaymentInstructions()}
          <label className="upload-label">
            <input type="file" accept="image/*,.pdf" onChange={handleFileChange} />
            Submeter Comprovativo
          </label>
          <button onClick={handleSubmit} disabled={uploading || !proofURL} className="submit-proof-button">
            {uploading ? 'Uploading...' : 'Enviar Comprovativo'}
          </button>
        </div>
        <div className="order-summary">
          <h2>Encomenda recebida</h2>
          <p>Número da encomenda: {orderNumber}</p> {/* Use the stored order number */}
          <p>Data: {new Date().toLocaleDateString()}</p>
          <p>Total: {cart.reduce((sum, item) => sum + item.price * item.quantity, 0) + shippingCost}€</p>
          <p>Método de pagamento: {formData.paymentMethod}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
