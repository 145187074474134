import React from 'react';
import './Sustainability.css';

const Sustainability = () => {
  return (
    <div className="sustainability">
      <header className="sustainability-header">
        <h1>Responsabilidade Social 🍃</h1>
        <p>
        Na Conta&Meia, a sustentabilidade é uma prioridade. Todas as nossas peças são 100% sustentáveis, garantindo uma produção totalmente livre de emissões de carbono, desde a fabricação até à entrega em sua casa. Contudo, a nossa ambição vai mais além: estamos empenhados em ter um impacto positivo no meio ambiente, trabalhamos para alcançar uma pegada de carbono neutra e contribuir para um planeta mais verde e saudável.        </p>
      </header>
      <section className="sustainability-content">
        <div className="card">
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M17 32.41L24 29l7 3.41v7.5L24 44l-7-4.09zM8 4c.455 8.333 6 25 16 25S40 12.784 40 4"/></svg>
          <h2>Na Produção</h2>
          <p>
          Na Conta&Meia, cada peça é cuidadosamente produzida à mão, através da utilização de materiais sustentáveis e técnicas de produção ecológicas. Optamos por fornecedores que partilham o nosso compromisso com o meio ambiente, garantindo que todos os materiais são obtidos de forma ética e sustentável. Durante o processo de criação o desperdício é minimizado. Com foco na qualidade e sustentabilidade, cada peça é um reflexo da nossa dedicação em preservar o planeta enquanto oferecemos produtos únicos e duradouros.
          </p>
        </div>
        <div className="card">
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2m10 0H9m10 0h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14"/><circle cx="17" cy="18" r="2"/><circle cx="7" cy="18" r="2"/></g></svg>
          <h2>No Empacotamento</h2>
          <p>
          Rejeitamos o uso de plástico a 100% em todo o nosso packaging, optamos por materiais que respeitam o meio ambiente e promovam a sustentabilidade. As nossas embalagens são perfeitas para reutilização, principalmente para levares contigo os produtos Conta&Meia nas tuas viagens! Para além disto optamos por embalagens compactas e leves para minimizar o consumo de combustível durante o transporte, assim garantimos que os nossos produtos chegam ao destino com o menor impacto ambiental possível.          </p>
        </div>
        <div className="card">
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 256 256"><path fill="currentColor" d="m223.68 66.15l-88-48.15a15.88 15.88 0 0 0-15.36 0l-88 48.17a16 16 0 0 0-8.32 14v95.64a16 16 0 0 0 8.32 14l88 48.17a15.88 15.88 0 0 0 15.36 0l88-48.17a16 16 0 0 0 8.32-14V80.18a16 16 0 0 0-8.32-14.03M128 32l80.34 44l-29.77 16.3l-80.35-44Zm0 88L47.66 76l33.9-18.56l80.34 44ZM40 90l80 43.78v85.79l-80-43.75Zm176 85.78l-80 43.79v-85.75l32-17.51V152a8 8 0 0 0 16 0v-44.45L216 90v85.77Z"/></svg>
          <h2>No Transporte</h2>
          <p>
          Na Conta&Meia, encaramos a sustentabilidade em cada etapa do nosso processo, inclusive no transporte das nossas peças. Todos os nossos produtos são enviados via CTT, uma empresa comprometida com a redução do impacto ambiental. Além disso, optamos por embalagens compactas e leves para minimizar o consumo de combustível durante o transporte. Com estas medidas, garantimos que os nossos produtos chegam ao destino de forma sustentável e com o menor impacto possível.                   </p>
        </div>
      </section>
    </div>
  );
};

export default Sustainability;
