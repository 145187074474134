import React, { useState } from 'react';
import { db } from '../firebase'; // Adjust import based on your file structure
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('pt-PT'); // Format: DD/MM/YYYY
    const formattedTime = currentDate.toLocaleTimeString('pt-PT'); // Format: HH:MM:SS

    try {
      await addDoc(collection(db, 'contacto'), {
        ...formData,
        date: formattedDate, // Store the current date
        time: formattedTime, // Store the current time
        timestamp: serverTimestamp() // Store Firebase's server timestamp
      });
      alert('Mensagem enviada com sucesso!');
      setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    } catch (error) {
      console.error('Erro ao enviar mensagem: ', error);
      alert('Erro ao enviar mensagem. Tente novamente.');
    }
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Contacte-nos</title>
        <meta name="description" content="tem alguma duvida ou questão? não hesite em contactar-nos, faremos o nosso melhor para responder o mais rápidamente possivel" />
        <meta name="keywords" content="jewelry, handmade,history, Conta&Meia, about us, stories , contaemeia, bijutaria, contacto, pulseiras , brincos, aneis, colares" />
        <meta property="og:title" content="Conta&Meia - Unique Jewelry for Every Story" />
        <meta property="og:description" content="Discover handcrafted jewelry pieces in our New Beginnings collection." />
        <meta property="og:image" content="https://https://contaemeia.com/favicon.ico" />
      </Helmet>
      <header className="contact-header">
        <h1>Contactos</h1>
      </header>
      <div className="contact-content">
        <section className="contact-info">
          <p>help.contaemeia@gmail.com</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/Conta.E.Meiaa/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: 'black' }} />
            </a>
            <a href="https://www.instagram.com/conta.e.meia/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: 'black' }} />
            </a>
            <a href="https://vm.tiktok.com/ZIJWNMqVa/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: 'black' }} />
            </a>
          </div>
        </section>
        <section className="contact-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nome"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Telefone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Assunto"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="A sua mensagem ..."
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Enviar</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Contact;
